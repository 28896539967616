const config = {
  company: 'MATISA Matériel Industriel S.A.' || 'vib GmbH',
  logoURI:
    'https://utils.vib-development.ch/matisa/ecm/logo.svg' || 'https://utils.vib-development.ch/vib/logo.svg',
  logoWhiteURI:
    'https://utils.vib-development.ch/matisa/ecm/logo-white.svg' || 'https://utils.vib-development.ch/vib/logo-white.svg',

  address: 'MATISA Matériel Industriel S.A.,Boulevard de l’Arc-en-Ciel 25,CH-1023 Crissier'?.split(',') || [
    'vib GmbH',
    'Grabenstrasse 12',
    '6340 Baar',
  ],
  contact: '+41-21-631 21 11,support@vib-connect.com'?.split(',') || [
    '+41 (0)41 763 16 94',
    'support@vib-gmbh.ch',
  ],
  apibaseuri:
    'https://api.matisa-manual.vib-connect.com' ||
    'https://api.main.vib-connect-manual.ch4.amazee.io',
  appname: 'Matisa ECM Manual' || 'vib connect Manual',
  appdomain: 'matisa-manual.vib-connect.com' || 'manual.vib-gmbh.ch',
  appurl: 'https://matisa-manual.vib-connect.com' || 'https://manual.vib-gmbh.ch',
  statuspage: 'https://status.vib-development.ch' || 'https://status.vib-development.ch',
  support: 'support@vib-connect.com' || 'support@vib-gmbh.ch',
  root: '1OpcxeiPN-RTujH2s8EPRg8yUT7O3iidP' || '',
  footer: '1lvaQeds9f4Bb3iHrjO2DYPufDnOYZ_fa' || '',
  disclaimer: '1Q6XdwiSO-Xq_kq3mpleJEnKF4T6QJX6Tw5U-zQpf2Dc' || '',
  languages: 'EN-US,DE,ES,IT,FR'?.split(',') || ['DE','EN-GB'],
  screenBreak: '930' || 930
};

export default config;
